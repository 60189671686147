







import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  props: {
    value: {
      type: String,
      required: true,
      default: "",
    },
  },
  setup() {
    return {};
  },
});
